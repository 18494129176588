import React, { useContext } from 'react';
import { GlobalContext } from '../../global-context';
import { makeStyles } from '@material-ui/core';
import { Paper } from '@material-ui/core/';
import IframeComm from 'react-iframe-comm';

const log = false;

////////// COMPONENT //////////
export default function ConciergeDetailsTracking(props) {
  const { width } = useContext(GlobalContext);
  const cls = useStyles();
  const { trackingLink } = props;

  const attributes = {
    id: `iTrack`,
    src: trackingLink,
    width: `100%`,
    height: width === `xs` ? `400px` : (width === `sm` ? `540px` : `720px`),
  }

  const postMessageData = `Hi`;

  const onReceiveMessage = (message) => {
    if (message.data) {
      log && console.log(`iFrame Response:`, message.data);
    }
    else {
      console.log(`Error: No iframe data found!`);
    }
  }

  const onReady = () => {

  }

  return (<>
    
    <div className={cls.map}>
      <Paper className={cls.paper} elevation={0}/>
      <IframeComm
        attributes={attributes}
        postMessageData={postMessageData}
        handleReady={onReady}
        handleReceiveMessage={onReceiveMessage}
      />
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  map: {
    border: `1px solid ${theme.palette.text.primary}`,
    position: 'relative'
  },
  paper: {
    height: '2.5rem',
    width: '10rem',
    backgroundColor: '#282828',
    position: 'absolute',
    top: 0,
    left: 0,
    border: 'none'
  }
}));