import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip, Button } from '@material-ui/core';

import Carousel from "../../reusable/Carousel";

const placeholder = require('../../static/vehicle-placeholder.jpg');

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
export default function ConciergeDetailsInfo(props) {
  const cls = useStyles();
  const { concierge, loaner, lyft, tookan } = props;

  const [loanerPickupCarousel, setLoanerPickupCarousel] = useState(false);
  const [loanerDeliveryCarousel, setLoanerDeliveryCarousel] = useState(false);
  const [consumerPickupCarousel, setConsumerPickupCarousel] = useState(false);
  const [consumerDeliveryCarousel, setConsumerDeliveryCarousel] = useState(false);

  // Get driver initials for placeholder driver image
  const getDriverInitials = () => {
    let fullName = concierge.driver_name ? concierge.driver_name : `N/A`
    if (fullName !== `N/A`) {
      let names = fullName.split(` `);
      let firstI = names[0][0];
      let secondI = names[names.length - 1][0];
      return `${firstI}${secondI}`.toUpperCase();
    }
    else return fullName;
  }

  // Get title & vehicle name based on if using a lyft, loaner, or consumer move
  const getVehicleTxt = () => {
    if (loaner) return `Loaner\xa0Vehicle:`;
    else return `Arrival\xa0Vehicle:`;
  }
  const getVehicle = (myMove) => {
    if (myMove) {
      if (!myMove.vehicle_make || !myMove.vehicle_model) return `No Vehicle Info`;
      else if (!myMove.vehicle_year) return `${myMove.vehicle_make} ${myMove.vehicle_model}`;
      else if (!myMove.vehicle_color) return `${myMove.vehicle_year} ${myMove.vehicle_make} ${myMove.vehicle_model}`;
      else return `${myMove.vehicle_year} ${myMove.vehicle_make} ${myMove.vehicle_model} (${capFirst(myMove.vehicle_color)})`;
    }
  }
  const getLyftVehicle = (myMove) => {
    if (myMove) {
      if (!myMove.driver_vehicle_make || !myMove.driver_vehicle_model) return `No Vehicle Info`;
      else if (!myMove.driver_vehicle_year) return `${myMove.driver_vehicle_make} ${myMove.driver_vehicle_model}`;
      else if (!myMove.driver_vehicle_color) return `${myMove.driver_vehicle_year} ${myMove.driver_vehicle_make} ${myMove.driver_vehicle_model}`;
      else return `${myMove.driver_vehicle_year} ${myMove.driver_vehicle_make} ${myMove.driver_vehicle_model} (${capFirst(myMove.driver_vehicle_color)})`;
    }
  }

  // Get the vehicle image & tooltip text based on move status
  const getVehicleImageTxt = () => {
    if (tookan && (tookan.consumerPickupImage || tookan.consumerDeliveryImage)) return getVehicle(concierge);
    else if (tookan && (tookan.loanerPickupImage || tookan.loanerDeliveryImage)) return getVehicle(loaner);
    else if (lyft && lyft.driver_vehicle_img_url) return getLyftVehicle(lyft);
    else return `No Vehicle Info`;
  }
  const getVehicleImage = () => {
    if (tookan && tookan.consumerDeliveryImage) return tookan.consumerDeliveryImage;
    else if (tookan && tookan.consumerPickupImage) return tookan.consumerPickupImage;
    else if (tookan && tookan.loanerDeliveryImage) return tookan.loanerDeliveryImage;
    else if (tookan && tookan.loanerPickupImage) return tookan.loanerPickupImage;
    else if (lyft && lyft.driver_vehicle_img_url) return lyft.driver_vehicle_img_url;
    else return placeholder;
  }

  // Get the displayed status based on the loaner move on consumer move
  const getStatus = () => {
    if (concierge.cancel_status) {
      if (concierge.cancel_status === `canceled`) return `Cancelled`;
      else if (concierge.cancel_status === `pending`) return `Cancel Pending`;
      else if (concierge.cancel_status === `seen`) return `Cancel Seen`;
      else if (concierge.cancel_status === `started`) return `Cancel Started`;
      else if (concierge.cancel_status === `delivered`) return `Cancel Delivered`;
      else return `Waiting`;
    }
    else if (loaner && loaner.status && loaner.sequence < concierge.sequence) {
      if ((loaner.status === `pickup arrived` || loaner.status === `pickup successful`) && (!concierge.status || concierge.status === `dispatched`)) return `Driver is picking up loaner vehicle`;
      else if (loaner.status === `delivery started` && (!concierge.status || concierge.status === `dispatched`)) return `Driver is en route to your location`;
      else if (loaner.status === `delivery arrived` && (!concierge.status || concierge.status === `dispatched`)) return `Driver has arrived at your location`;
      else if (loaner.status === `delivery successful` && (!concierge.status || concierge.status === `dispatched` || concierge.status.includes(`pickup`))) return `Driver is picking up your vehicle`;
      else if (concierge.status === `delivery started`) return `Vehicle is en route to service location`;
      else if (concierge.status === `delivery arrived`) return `Vehicle has arrived at service location`;
      else if (concierge.status === `delivery successful`) return `Vehicle has been delivered successfully`;
      else return `Waiting`;
    }
    else if (loaner && loaner.status && loaner.sequence > concierge.sequence) {
      if ((loaner.status === `pickup arrived` || loaner.status === `pickup successful`) && (!concierge.status || concierge.status === `dispatched`)) return `Driver is picking up loaner vehicle`;
      else if (loaner.status === `delivery started` && (!concierge.status || concierge.status === `dispatched`)) return `Driver is en route to your location`;
      else if (loaner.status === `delivery arrived` && (!concierge.status || concierge.status === `dispatched`)) return `Driver has arrived at your location`;
      else if (loaner.status === `delivery successful` && (!concierge.status || concierge.status === `dispatched` || concierge.status.includes(`pickup`))) return `Driver is picking up your vehicle`;
      else if (concierge.status === `delivery started`) return `Vehicle is en route to service location`;
      else if (concierge.status === `delivery arrived`) return `Vehicle has arrived at service location`;
      else if (concierge.status === `delivery successful`) return `Vehicle has been delivered successfully`;
      else return `Waiting`;
    }
    else if (concierge.status) {
      if (concierge.status === `pickup started`) return concierge.consumer_at_pickup ? `Driver is en route to your location` : `Driver is en route to service location`;
      else if (concierge.status === `pickup arrived`) return concierge.consumer_at_pickup ? `Driver has arrived at your location` : `Driver has arrived at service location`;
      else if (concierge.status === `pickup successful`) return concierge.consumer_type === 'customer' ? `Driver is picking up your vehicle` : `Driver is picking up loaner vehicle`;
      else if (concierge.status === `delivery started`) return concierge.consumer_at_pickup ? `Vehicle is en route to service location` : `Vehicle is en route to your location`;
      else if (concierge.status === `delivery arrived`) return concierge.consumer_at_pickup ? `Vehicle has arrived at service location` : `Vehicle has arrived at your location`;
      else if (concierge.status === `delivery successful`) return `Vehicle has been delivered successfully`;
      else return `Waiting`;
    }
    else return `Waiting`;
  }

  // Get title & expected time based on the move status
  const getTimeTxt = () => {
    if (concierge.status && concierge.status.includes(`delivery`) && concierge.delivery_time) return `Expected\xa0Delivery\xa0Time:`;
    else if (concierge.pickup_time) return `Expected\xa0Pickup\xa0Time:`;
    else return `Start\xa0Time`;
  }
  const getTime = () => {
    if (concierge.status && concierge.status.includes(`delivery`) && concierge.delivery_time) return moment(concierge.delivery_time).format(`LT`);
    else if (concierge.pickup_time) return moment(concierge.pickup_time).format(`LT`);
    else if (loaner) return moment(loaner.pickup_started || null).format(`LT`);
    else return moment(concierge.pickup_started || null).format(`LT`);
  }

  // Get only the first name of the driver
  const getDriverName = () => {
    if (concierge.driver_name) return concierge.driver_name.split(` `)[0];
    else return `HopDriver`;
  }

  return (<>
    <div className={cls.main}>

      <div className={cls.info}>
        <Typography className={cls.infoTxt}>VEHICLE</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={getVehicleImageTxt()}>
          <img className={cls.infoImage} src={getVehicleImage()} alt="vehicle" />
        </Tooltip>
      </div>

      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>Information</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>{moment(concierge.pickup_time || ``).format(`MM/DD/YYYY`)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>#{concierge.id}</Typography>
          </Grid>
        </Grid>

        <div className={cls.break} />

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Your&nbsp;Vehicle:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getVehicle(concierge)}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>{getVehicleTxt()}&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{loaner ? getVehicle(loaner) : getLyftVehicle(lyft)}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>{getTimeTxt()}&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getTime()}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Current&nbsp;Status:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getStatus()}</Typography>
            </div>
          </Grid>

          <div className={cls.hiddenLineBreak} />

          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getDriverName()}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Dealership:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{concierge.customer.name || `Unknown Dealer`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Your&nbsp;Name:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{concierge.consumer_name || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Your&nbsp;Phone&nbsp;#:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{concierge.consumer_phone || `-`}</Typography>
            </div>
          </Grid>

          {tookan ? <>
            <div className={cls.break} />

            <Grid container spacing={1}>
              {loaner ? <>
                <Grid item sm={6} xs={12}>
                  <Button fullWidth disabled={!tookan.loanerPickupImages || !tookan.loanerPickupImages.length > 0} variant="contained" color="secondary" className={cls.btn} onClick={() => setLoanerPickupCarousel(true)}>Loaner Pickup&nbsp;Images</Button>
                  {loanerPickupCarousel && <Carousel images={tookan.loanerPickupImages} setCarousel={setLoanerPickupCarousel} />}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Button fullWidth disabled={!tookan.loanerDeliveryImages || !tookan.loanerDeliveryImages.length > 0} variant="contained" color="secondary" className={cls.btn} onClick={() => setLoanerDeliveryCarousel(true)}>Loaner Delivery&nbsp;Images</Button>
                  {loanerDeliveryCarousel && <Carousel images={tookan.loanerDeliveryImages} setCarousel={setLoanerDeliveryCarousel} />}
                </Grid>
              </> : null}

              <Grid item sm={6} xs={12}>
                <Button fullWidth disabled={!tookan.consumerPickupImages || !tookan.consumerPickupImages.length > 0} variant="contained" color="primary" className={cls.btn} onClick={() => setConsumerPickupCarousel(true)}>Your Vehicle's Pickup&nbsp;Images</Button>
                {consumerPickupCarousel && <Carousel images={tookan.consumerPickupImages} setCarousel={setConsumerPickupCarousel} />}
              </Grid>
              <Grid item sm={6} xs={12}>
                <Button fullWidth disabled={!tookan.consumerDeliveryImages || !tookan.consumerDeliveryImages.length > 0} variant="contained" color="primary" className={cls.btn} onClick={() => setConsumerDeliveryCarousel(true)}>Your Vehicle's Delivery&nbsp;Images</Button>
                {consumerDeliveryCarousel && <Carousel images={tookan.consumerDeliveryImages} setCarousel={setConsumerDeliveryCarousel} />}
              </Grid>
            </Grid>
          </> : null}
        </Grid>
      </div>

      <div className={cls.info}>
        <Typography className={cls.infoTxt}>DRIVER</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={getDriverName()}>
          {tookan && tookan.driver && tookan.driver.fleet_thumb_image ?
            <img className={cls.infoImage} src={tookan.driver.fleet_thumb_image} alt="driver" /> :
            <div className={cls.infoImage}>
              <Typography className={cls.infoNoDriverTxt}>{getDriverInitials()}</Typography>
            </div>
          }
        </Tooltip>
      </div>

    </div >
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  infoImage: {
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    width: '128px',
    height: '128px',
    border: `1px solid #cdd6e5`,
    borderRadius: '50%',
    background: `#cdd6e5`,
    verticalAlign: 'top',
  },
  infoNoVehicleTxt: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 700,
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: 48,
    fontWeight: 700,
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: `default`,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  btn: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.softDivider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.softDivider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));