import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

process.env.REACT_APP_ENV !== 'development' && Sentry.init({
  dsn: "https://14aa72fe72874ccba66813db0f679b7e@o491290.ingest.sentry.io/5556745",
  autoSessionTracking: true,
  environment: process.env.REACT_APP_ENV,
  release: "sat-concierge@v1.1.0",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
