import React, { useState, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../global-context';

import { makeStyles, Container, Grid, Typography } from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from "graphql-tag";
import { Subscription } from "react-apollo";

import Loading from '../utils/Loading';
import ConciergeStepper from './conciergeDetails/ConciergeStepper';
import ConciergeDetailsInfo from './conciergeDetails/ConciergeDetailsInfo';
import ConciergeDetailsLocation from './conciergeDetails/ConciergeDetailsLocation';
import ConciergeDetailsTracking from './conciergeDetails/ConciergeDetailsTracking';

const log = false;

////////// COMPONENT //////////
export default function ConciergeDetails(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [tookan, setTookan] = useState({ loading: true });

  const GET_TOOKAN_DATA = async (job_id) => axios.post(`/.netlify/functions/handleTookanData`, { job_id: job_id });
  const GET_TOOKAN_DRIVERS = async () => axios.post(`/.netlify/functions/handleTookanDrivers`);

  const getTookanData = async (move = null, loaner = null) => {
    log && console.log(`Getting data from tookan...`);

    let loanerPickupJobId = loaner && loaner.pickup_stop_id ? loaner.pickup_stop_id : null;
    let loanerDeliveryJobId = loaner && loaner.delivery_stop_id ? loaner.delivery_stop_id : null;
    let loanerPickupImages = [];
    let loanerDeliveryImages = [];
    let loanerPickupImage = null;
    let loanerDeliveryImage = null;

    let consumerPickupJobId = move && move.pickup_stop_id ? move.pickup_stop_id : null;
    let consumerDeliveryJobId = move && move.delivery_stop_id ? move.delivery_stop_id : null;
    let consumerPickupImages = [];
    let consumerDeliveryImages = [];
    let consumerPickupImage = null;
    let consumerDeliveryImage = null;

    let driver = null;

    // Tookan LOANER PICKUP images
    if (loanerPickupJobId) {
      await GET_TOOKAN_DATA(loanerPickupJobId)
        .then(res => {
          log && console.log(`TOOKAN Loaner Pickup Response:`, res.data.data[0]);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) loanerPickupImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedImages = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedImages && extractedImages.length > 0) {
                for (let i = 0; i < extractedImages.length; i++) {
                  if (extractedImages[i].includes(`task_images`)) loanerPickupImages.push(extractedImages[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Failed to retrieve Tookan LOANER PICKUP data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan LOANER DELIVERY images
    if (loanerDeliveryJobId) {
      await GET_TOOKAN_DATA(loanerDeliveryJobId)
        .then(res => {
          log && console.log(`TOOKAN Loaner Delivery Response:`, res.data.data[0]);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) loanerDeliveryImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`)) loanerDeliveryImages.push(extractedData[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Error retrieving Tookan LOANER DELIVERY data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan CONSUMER PICKUP images
    if (consumerPickupJobId) {
      await GET_TOOKAN_DATA(consumerPickupJobId)
        .then(res => {
          log && console.log(`TOOKAN Consumer Pickup Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) consumerPickupImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`)) consumerPickupImages.push(extractedData[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Failed to retrieve Tookan CONSUMER PICKUP data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan CONSUMER DELIVERY images
    if (consumerDeliveryJobId) {
      await GET_TOOKAN_DATA(consumerDeliveryJobId)
        .then(res => {
          log && console.log(`TOOKAN Consumer Delivery Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) consumerDeliveryImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`)) consumerDeliveryImages.push(extractedData[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Error retrieving Tookan CONSUMER DELIVERY data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DRIVER data
    if (move && move.driver_id) {
      await GET_TOOKAN_DRIVERS()
        .then(res => {
          log && console.log(`TOOKAN Driver Response:`, res);
          if (res.status === 200 && res.data.data.length > 0) {
            let tookanDrivers = res.data.data.filter(driver => driver.fleet_id === move.driver_id);
            if (tookanDrivers.length > 0) driver = tookanDrivers[0];
          }
        })
        .catch(err => {
          console.log(`Failed to retrieve Tookan drivers:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Set Tookan state object
    setTookan({
      loading: false,
      loanerPickupImages: loanerPickupImages,
      loanerDeliveryImages: loanerDeliveryImages,
      loanerPickupImage: loanerPickupImage,
      loanerDeliveryImage: loanerDeliveryImage,
      consumerPickupImages: consumerPickupImages,
      consumerDeliveryImages: consumerDeliveryImages,
      consumerPickupImage: consumerPickupImage,
      consumerDeliveryImage: consumerDeliveryImage,
      driver: driver
    });
  }

  const fetchTookan = (move, loaner = null) => {
    getTookanData(move, loaner);
    if (move.status && move.cancel_status === null && move.status !== `delivery successful` && (move.status.includes(`pickup`) || move.status.includes(`delivery`) || move.status === `dispatched`)) {
      setInterval(() => getTookanData(move, loaner), 30000);
    }
  };

  return (<>
    <div className={cls.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}><Typography className={cls.headL}>Track Your Vehicle</Typography></Grid>
          <Grid item md={6} xs={12}><Typography className={cls.headR}>*If you have any issues, please call <a className={cls.phone} href={`tel:18009137674`}>(800)&nbsp;913-7674</a></Typography></Grid>
        </Grid>

        <div style={{ width: '100%', height: '24px' }} />

        <Subscription
          subscription={GET_CONCIERGE_MOVE}
          onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve concierge move: ` + err.toString())}>
          {({ loading, error, data }) => {
            if (loading) return <Loading fixed />
            if (error) {
              console.log(`Failed to retrieve concierge move:`, error);
              return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>ERROR FINDING CONCIERGE RECORD</Typography>
                </div>
              )
            }
            if (data && data.moves && data.moves.length > 0) {
              const concierge = data.moves[0];
              const loaner = concierge.moveByReturnRideId && concierge.moveByReturnRideId.tags && concierge.moveByReturnRideId.tags.includes(`loaner`) ? concierge.moveByReturnRideId : concierge.parent_move && concierge.parent_move.consumer_type && concierge.parent_move.consumer_type === 'loaner' ? concierge.parent_move : null;
              const lyft = concierge.lyftride && concierge.lyftride.activeAttempt ? concierge.lyftride.activeAttempt : null;
              log && console.log(`Concierge Details:`, concierge);
              log && console.log(`Loaner Details:`, loaner);
              log && console.log(`Lyft Details:`, lyft);

              if (tookan.loading) {
                fetchTookan(concierge, loaner)
              }
              return (<>
                <ConciergeStepper concierge={concierge} loaner={loaner} />
                <ConciergeDetailsInfo concierge={concierge} loaner={loaner} lyft={lyft} tookan={tookan} />

                <Divide spacer tip="See information on where your vehicle is being picked up from as well as where it is being delivered">Location Details</Divide>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <ConciergeDetailsLocation title="Your Location" type="pickup" concierge={concierge} loaner={loaner} />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ConciergeDetailsLocation title="Service Location" type="delivery" concierge={concierge} loaner={loaner} />
                  </Grid>
                </Grid>

                {loaner && loaner.status && (loaner.status.includes(`pickup`) || loaner.status.includes(`delivery`)) && loaner.status !== `delivery successful` && loaner.tracking_link ? <>
                  <Divide spacer tip="Track where our driver & loaner vehicle are currently located">Tracking Map</Divide>
                  <ConciergeDetailsTracking trackingLink={loaner.tracking_link} />
                </> : null}

                {concierge && concierge.status && (concierge.status.includes(`pickup`) || concierge.status.includes(`delivery`)) && concierge.status !== `delivery successful` && concierge.tracking_link ? <>
                  <Divide spacer tip="Track where our driver & your vehicle are currently located">Tracking Map</Divide>
                  <ConciergeDetailsTracking trackingLink={concierge.tracking_link} />
                </> : null}
              </>)
            }
            else return (
              <div className={cls.notFound}>
                <Typography className={cls.notFoundTxt}>NO CONCIERGE RECORD FOUND</Typography>
              </div>
            )
          }}
        </Subscription>

      </Container>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  headL: {
    verticalAlign: 'top',
    lineHeight: 1,
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  headR: {
    verticalAlign: 'top',
    lineHeight: 1.333,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  phone: {
    fontWeight: 600,
    textDecoration: 'underline',
    color: theme.palette.secondary.main
  },
  notFound: {
    padding: theme.spacing(4),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

////////// GRAPHQL //////////
const GET_CONCIERGE_MOVE = gql`
  subscription {
    moves(
      where: {
        _or: [
          {consumer_pickup: {_eq: true}}
          {_and: [
            {consumer_type: {_eq: "customer"}}
            {consumer_pickup: {_eq: false}}
          ]}
        ]
      }
      order_by: {sequence: asc_nulls_last}
    ) {
      sequence
      id
      consumer_pickup
      consumer_name
      consumer_phone
      consumer_type
      consumer_at_pickup
      status
      cancel_status
      driver_id
      driver_name
      customer {
        config
        id
        name
        branded
      }
      lane {
        id
        distance_miles
        pickup {
          id
          name
          address
        }
        delivery {
          id
          name
          address
        }
      }
      pickup_stop_id
      delivery_stop_id
      pickup_time
      delivery_time
      pickup_arrived
      delivery_arrived
      tracking_link
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      move_type
      parent_move {
        sequence
        consumer_type
        consumer_at_pickup
        id
        status
        cancel_status
        tags
        pickup_stop_id
        delivery_stop_id
        pickup_time
        delivery_time
        pickup_arrived
        delivery_arrived
        tracking_link
        vehicle_make
        vehicle_model
        vehicle_year
        vehicle_color
      }
      moveByReturnRideId {
        sequence
        id
        status
        cancel_status
        tags
        pickup_stop_id
        delivery_stop_id
        pickup_time
        delivery_time
        pickup_arrived
        delivery_arrived
        tracking_link
        vehicle_make
        vehicle_model
        vehicle_year
        vehicle_color
      }
      lyftride {
        activeAttempt {
          id
          status
          estimated_arrival_time
          driver_vehicle_img_url
          driver_vehicle_year
          driver_vehicle_make
          driver_vehicle_model
          driver_vehicle_color
          driver_vehicle_license_plate
          driver_vehicle_license_plate_state
        }
      }
    }
  }
`;