import React, { useState } from 'react';
import { makeStyles, Typography, IconButton, Icon } from '@material-ui/core';

////////// COMPONENT //////////
export default function Carousel(props) {
  const classes = useStyles();
  const { images, setCarousel } = props;
  const [image, setImage] = useState(0);

  const handlePrevious = () => {
    if (image - 1 < 0) setImage(images.length - 1);
    else setImage(image - 1);
  }
  const handleNext = () => {
    if (image + 1 > images.length - 1) setImage(0);
    else setImage(image + 1);
  }
  const handleWheel = (event) => {
    if (event.deltaY >= 0) handleNext();
    else handlePrevious();
  }

  return (<>
    <div className={classes.root} onWheel={(event) => handleWheel(event)}>
      <Icon className={classes.icon + " " + classes.close} onClick={() => setCarousel(false)}>cancel</Icon>
      {images.length > 0 ? <>
        <IconButton onClick={() => handlePrevious()}>
          <Icon className={classes.icon + " " + classes.previous}>chevron_left</Icon>
        </IconButton>
        <IconButton onClick={() => handleNext()}>
          <Icon className={classes.icon + " " + classes.next}>chevron_right</Icon>
        </IconButton>
        <img id="carouselImg" className={classes.image} src={images[image]} alt="" />
      </> : <>
          <Typography className={classes.notFound}>NO&nbsp;IMAGES&nbsp;FOUND</Typography>
        </>}
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1300,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000000cc',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '60%',
    maxHeight: '80%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      maxHeight: '75%',
    },
  },
  icon: {
    position: 'fixed',
    cursor: 'pointer',
    color: '#ffffff80',
    transition: '0.15s',
    fontSize: 60,
    "&:hover": {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 48,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
  },
  close: {
    top: theme.spacing(4),
    right: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  previous: {
    top: '47%',
    left: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      top: '48%',
      left: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      top: 'initial',
      bottom: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  next: {
    top: '47%',
    right: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      top: '48%',
      right: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      top: 'initial',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  notFound: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    color: theme.palette.common.white,
  },
}));