import React from 'react';
import { makeStyles, Stepper, Step, StepLabel } from '@material-ui/core';

////////// COMPONENT //////////
export default function ConciergeStepper(props) {
  const cls = useStyles();
  const { concierge, loaner } = props;

  let steps = [];

  if (loaner) {
    console.log("loaner", loaner.sequence < concierge.sequence ? "L first" : "C first")
    if (loaner.sequence < concierge.sequence) steps = [
    `Driver is picking up loaner vehicle`,
    `Driver is en route to your location`,
    `Driver has arrived at your location`,
    `Driver is picking up your vehicle`,
    `Vehicle is en route to service location`,
    `Vehicle has arrived at service location`,
    `Vehicle has been delivered successfully`,
  ]
  else steps = [
    `Driver is en route to service location`,
    `Driver has arrived at service location`,
    `Driver is picking up your vehicle`,
    `Vehicle is en route to your location`,
    `Vehicle has arrived at your location`,
    `Vehicle has been delivered successfully`,
  ]
}
  else steps = [
    concierge.consumer_at_pickup ? `Driver is en route to your location` : `Driver is en route to service location`,
    concierge.consumer_at_pickup ? `Driver has arrived at your location` : `Driver has arrived at service location`,
    concierge.consumer_type === 'customer' ? `Driver is picking up your vehicle` : `Driver is picking up loaner vehicle`,
    concierge.consumer_at_pickup ? `Vehicle is en route to service location` : `Vehicle is en route to your location`,
    concierge.consumer_at_pickup ? `Vehicle has arrived at service location` : `Vehicle has arrived at your location`,
    `Vehicle has been delivered successfully`,
  ]

  const getActiveStep = () => {
    if (loaner && loaner.status && loaner.sequence < concierge.sequence) {
      if ((loaner.status === `pickup arrived` || loaner.status === `pickup successful`) && (!concierge.status || concierge.status === `dispatched`)) return 0;
      else if (loaner.status === `delivery started` && (!concierge.status || concierge.status === `dispatched`)) return 1;
      else if (loaner.status === `delivery arrived` && (!concierge.status || concierge.status === `dispatched`)) return 2;
      else if (loaner.status === `delivery successful` && (!concierge.status || concierge.status === `dispatched` || concierge.status.includes(`pickup`))) return 3;
      else if (concierge.status === `delivery started`) return 4;
      else if (concierge.status === `delivery arrived`) return 5;
      else if (concierge.status === `delivery successful`) return 7;
      else return -1;
    }
    if (loaner && concierge.status && loaner.sequence > concierge.sequence) {
      if (concierge.status === `pickup started`) return 0;
      else if (concierge.status === `pickup arrived`) return 1;
      else if (concierge.status === `pickup successful`) return 2;
      else if (concierge.status === `delivery started`) return 3;
      else if (concierge.status === `delivery arrived`) return 4;
      else if (concierge.status === `delivery successful`) return 6;
      else return -1;
    }
    else if (concierge.status) {
      if (concierge.status === `pickup started`) return 0;
      else if (concierge.status === `pickup arrived`) return 1;
      else if (concierge.status === `pickup successful`) return 2;
      else if (concierge.status === `delivery started`) return 3;
      else if (concierge.status === `delivery arrived`) return 4;
      else if (concierge.status === `delivery successful`) return 6;
      else return -1;
    }
    else return -1;
  }

  return (<>
    <div className={cls.mainL}>
      <Stepper activeStep={getActiveStep()} alternativeLabel className={cls.stepperL}>
        {steps && steps.length > 0 && steps.map((step, i) =>
          <Step key={`step-${i}`}>
            <StepLabel className={cls.stepTxtL}>{step}</StepLabel>
          </Step>
        )}
      </Stepper>
    </div>

    <div className={cls.mainS}>
      <Stepper activeStep={getActiveStep()} orientation="vertical" className={cls.stepperS}>
        {steps && steps.length > 0 && steps.map((step, i) =>
          <Step key={`step-${i}`}>
            <StepLabel className={cls.stepTxtS}>{step}</StepLabel>
          </Step>
        )}
      </Stepper>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  mainL: {
    display: 'block',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepperL: {
    padding: 0,
    border: 0,
    margin: 0,
    background: '#ffffff00',
  },
  stepTxtL: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: 14,
  },
  mainS: {
    display: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  stepperS: {
    background: '#ffffff00',
  },
  stepTxtS: {
    lineHeight: 1.25,
    fontSize: 14,
  },
}));