import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import ConciergeDetails from './ConciergeDetails';
import { GlobalContext } from '../global-context';
import { ApolloProvider } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles, Typography, Container } from '@material-ui/core';
import Loading from '../utils/Loading';

export default function LandingPage() {
  const cls = useStyles();
  const ctx = useContext(GlobalContext)

  const [ apollo, setApollo ] = useState(null)

  useEffect(() => {
    if (ctx.apolloClient) setApollo(true)
    else setApollo(false)
  }, [ctx.apolloClient])

  const handleRender = () => {
    if (apollo === 'undefined') return <div className={cls.root}>
      <Container maxWidth="lg">
        <Typography className={cls.headL}>Track Your Vehicle</Typography>
        <div style={{ float: 'right' }}>
          <Typography className={cls.headR}>*If you have any issues, please call <a className={cls.phone} href={`tel:18009137674`}>(800) 913-7674</a></Typography>
        </div>
        <div style={{ width: '100%', height: '24px' }} />
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>ERROR FINDING CONCIERGE RECORD</Typography>
        </div>
      </Container>
    </div>
    else if (!ctx.theme) return <Loading fixed />
    else return <ApolloProvider client={ctx.apolloClient}>
      <ConciergeDetails />
    </ApolloProvider>
  }

  return (
    <React.Fragment>
      { handleRender() }
    </React.Fragment>
  )
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  headL: {
    verticalAlign: 'top',
    lineHeight: 1,
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  headR: {
    verticalAlign: 'top',
    lineHeight: 1.333,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  phone: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  notFound: {
    padding: theme.spacing(4),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));


