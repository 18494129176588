import React, { useContext, useEffect, useState } from "react";
import { makeStyles, AppBar } from "@material-ui/core";

import { GlobalContext } from "../global-context";

const log = false;

////////// COMPONENT //////////
export default function Nav() {
  const cls = useStyles();
  const ctx = useContext(GlobalContext);
  
  return (<>
    { ctx.theme && 
      <div className={cls.nav}>
        <AppBar className={cls.bar} >
          { ctx.logo ? <img className={ cls.logo } alt={ `logo` } src={ ctx.logo } /> : null }
        </AppBar>
      </div>
    } </>
  )
}

////////// STYLES //////////
const useStyles = makeStyles((theme) => ({
  nav: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "56px",
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    display: 'flex',
    top: 0,
    left: 0,
    width: "100%",
    height: "4rem",
    padding: "0 !important",
    boxShadow: theme.palette.secondary.main,
  },
  logo: {
    position: "absolute",
    margin: "0.5rem",
    left: "50%",
    transform: "translateX(-50%)",
    height: '2.6rem'
  }
}));

