import React from 'react';
import { makeStyles } from '@material-ui/core';

////////// COMPONENT //////////
export default function Content(props) {
  const cls = useStyles();

  return <div className={cls.content}>{props.children}</div>
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  content: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));