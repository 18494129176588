import React from 'react';
import { matchPath } from 'react-router'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { GlobalContext } from './global-context';
import NotificationPopup from './reusable/NotificationPopup';
import { withWidth, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';

import { Helmet } from 'react-helmet';

import Loading from './utils/Loading';
import LandingPage from './components/LandingPage';
import Nav from './components/Nav';
import Content from './components/Content';

let log = false;
var https = require('https')

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      logo: '',
      move: {}, 
      name: '',   
      notificationShow: false,
      notificationVariant: '',
      notificationMessage: '',
      handleNotifications: (show, variant = this.state.notificationVariant, message = this.state.notificationMessage) => this.setState(
        {
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message
        }
      ),
      theme: null,
      width: props.width,
      apolloClient: null,
      setupApollo: (token) => {
        if (log) { console.log('Setting up the Apollo...') }
        const authLink = setContext(async (_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          }
        }),
          wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL,
          httpurl = process.env.REACT_APP_GRAPHQL_URL,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query)
              return (
                kind === "OperationDefinition" && operation === "subscription"
              )
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          })
        if (log) { console.log('Apollo Client Initialized! ', client) }
        this.setState({ apolloClient: client });
      }
    }
  };

  componentDidMount = async () => {
    const paths = matchPath(window.location.pathname, { path:"/:id" });
    if (paths) this.state.id = paths.params.id; 
    try {
      await axios.post(`https://${process.env.REACT_APP_A0_SD}.auth0.com/oauth/token`,
        {
          grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
          username: this.state.id,
          password: this.state.id,
          client_id: process.env.REACT_APP_A0_ID,
          realm: process.env.REACT_APP_A0_REALM,
        })
        .then(res => {
          this.state.setupApollo(res.data.id_token);
          try {
            const agent = new https.Agent({rejectUnauthorized: false})
            axios({
              url: `https://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
              method: 'post',
              data: { query: GET_CONCIERGE_MOVE },
              headers: { authorization: `Bearer ${res.data.id_token}` },
              httpsAgent: agent
            }).then(res => { 
              this.setState({ move: res.data })
              this.getTheme() 
            })
          } catch (err) {
            console.log(`Unable to retreive move. ERR: ${err}`)
            this.getTheme() 
          }
        })
    } catch (err) { 
      console.log("ERROR:", err) 
      this.getTheme() 
    } 
  }

  getTheme = async () => {
    let customer; let manifest; let iconObj;
    let move = this.state.move; 

    if (move && Object.keys(move).length > 0) customer = this.state.move.data.moves[0].customer;

    let brandName = customer && customer.branded && customer.config.branding.name ? customer.config.branding.name : "HopDrive";
    let logoURL = customer && customer.branded && customer.config.branding.logo_url ? customer.config.branding.logo_url : "https://www.socialautotransport.com/wp-content/uploads/2018/05/sat-logo-white@2x.png";
    let primary = customer && customer.branded && customer.config.branding.primary_color ? { main: customer.config.branding.primary_color }: { veryLight: `#80c8ff`, light: `#50afff`, main: `#2096f8`, dark: `#1268b0`, veryDark: `#104072`, contrastText: `#fff` };
    let secondary = customer && customer.branded && customer.config.branding.secondary_color ? { main: customer.config.branding.secondary_color } : { veryLight: `#c0c8d0`, light: `#9098a0`, main: `#505860`, dark: `#202830`, veryDark: `#101820`, contrastText: `#fff` };

    if (brandName !== 'HopDrive') iconObj = { "src": logoURL, "type": "url" } 
    else iconObj = { "src": "favicon.ico", "sizes": "64x64 32x32 24x24 16x16", "type": "image/x-icon" } 

    this.setState({ 
      logo: logoURL,
      name: brandName,
    }); 

    manifest = {
      ...manifest, 
      "short_name": "CP",
      "icons": [iconObj],
      "name": `Consumer Portal | ${brandName}`,
      "start_url": ".",
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    }
    
    this.setState({ 
      theme: createMuiTheme({
        palette: {
          primary: primary,
          secondary: secondary,
          error: { veryLight: `#ffdddd`, light: `#e57373`, main: `#f44336`, dark: `#d32f2f`, contrastText: `#fff` },
          warning: { veryLight: `#ffffcc`, light: `#ffb74d`, main: `#ff9800`, dark: `#f57c00`, contrastText: `#fff` },
          info: { light: `#64b5f6`, main: `#2196f3`, dark: `#1976d2`, contrastText: `#fff` },
          success: { light: `#81c784`, main: `#4caf50`, dark: `#388e3c`, contrastText: `#fff` },
      
          text: {
            primary: `rgba(32, 40, 48, 0.8)`,
            secondary: `rgba(32, 40, 48, 0.5)`,
            disabled: `rgba(32, 40, 48, 0.4)`,
            hint: `rgba(32, 40, 48, 0.4)`,
            contrast: `#fff`,
          },
      
          softDivider: `rgba(32, 40, 48, 0.10)`,
          divider: `rgba(32, 40, 48, 0.15)`,
          hardDivider: `rgba(32, 40, 48, 0.2)`,
      
          action: {
            active: `rgba(32, 40, 48, 0.55)`,
            hover: `rgba(32, 40, 48, 0.05)`,
            selected: `rgba(32, 40, 48, 0.1)`,
            disabled: `rgba(32, 40, 48, 0.3)`,
            disabledBackground: `rgba(32, 40, 48, 0.15)`,
            focus: `rgba(32, 40, 48, 0.15)`,
          },
      
          background: {
            paper: `#fff`,
            default: `#f8f8f8`,
            light: `#f5f5f5`,
            main: `#f2f2f2`,
            dark: `#efefef`,
          },
      
          common: {
            black: `#000`,
            white: `#fff`,
          },
        },
        shape: {
          borderRadius: `4px`,
        },
        border: `1px solid rgba(32, 40, 48, 0.15)`,
        shadow: `0px 3px 8px #00000040`,
        typography: { useNextVariants: true },
      })
    })

    // SET MANIFEST && META TAGS -------------------------------------------------------------------------------------
    document.querySelector('#og_title').setAttribute('content', this.state.name);
    document.querySelector('#og_image').setAttribute('content', this.state.logo);
    log && console.log("Manifest", manifest)
  }

  render() { return (<>
    <div className="App">
      <Helmet>
          <meta name="description" id="description" content={ `Consumer Portal | ${ this.state.name }` }/>
          <link rel="icon" id="shortcut_icon"  href={ this.state.logo } type="image/png" size="16x16"/>
          <title id="meta_title">Consumer Portal | { this.state.name }</title>
      </Helmet>
    </div>
    <MuiThemeProvider theme={ this.state.theme }>
      <GlobalContext.Provider value={this.state}>
          <CssBaseline />
          <NotificationPopup
            show={this.state.notificationShow}
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            handleClose={this.state.handleNotifications}
          />
          <Nav />
          <Content>
            <Router>
              {/* Create a component that will handle calling out to Netlify function on mount and then render a child component with the move details (see LandingPage). The path can be configured to any format such as '/consumer/:id' to correlate to dealer.hopdrive.io/consumer/KQ74, where KQ74 is the hashed move ID */}
              <Route path="/:id/" exact component={LandingPage} />
            </Router>
          </Content>
      </GlobalContext.Provider>
    </MuiThemeProvider> 
  </> ) }
}

export default withWidth()(App);

const GET_CONCIERGE_MOVE = `
  query {
    moves(
      where: {
        _or: [
          {consumer_pickup: {_eq: true}}
          {_and: [
            {consumer_type: {_eq: "customer"}}
            {consumer_pickup: {_eq: false}}
          ]}
        ]
      }
      order_by: {sequence: asc_nulls_last}
    ) {
      sequence
      id
      consumer_pickup
      consumer_name
      consumer_phone
      consumer_type
      consumer_at_pickup
      status
      cancel_status
      driver_id
      driver_name
      customer {
        config
        id
        name
        branded
      }
      lane {
        id
        distance_miles
        pickup {
          id
          name
          address
        }
        delivery {
          id
          name
          address
        }
      }
      pickup_stop_id
      delivery_stop_id
      pickup_time
      delivery_time
      pickup_arrived
      delivery_arrived
      tracking_link
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      move_type
      parent_move {
        sequence
        consumer_type
        consumer_at_pickup
        id
        status
        cancel_status
        tags
        pickup_stop_id
        delivery_stop_id
        pickup_time
        delivery_time
        pickup_arrived
        delivery_arrived
        tracking_link
        vehicle_make
        vehicle_model
        vehicle_year
        vehicle_color
      }
      moveByReturnRideId {
        sequence
        id
        status
        cancel_status
        tags
        pickup_stop_id
        delivery_stop_id
        pickup_time
        delivery_time
        pickup_arrived
        delivery_arrived
        tracking_link
        vehicle_make
        vehicle_model
        vehicle_year
        vehicle_color
      }
      lyftride {
        activeAttempt {
          id
          status
          estimated_arrival_time
          driver_vehicle_img_url
          driver_vehicle_year
          driver_vehicle_make
          driver_vehicle_model
          driver_vehicle_color
          driver_vehicle_license_plate
          driver_vehicle_license_plate_state
        }
      }
    }
  }
`;